<template>
  <div>
    <round-header
      :showErrors="false"
      :showWarnings="false">
    </round-header>

    <div v-for="level in levels" :key="level.id">
      <h2>{{ level.LEVEL.name }}</h2>
      <div v-for="category in categories(level)" :key="category.id">
        <h3>{{ category.CATEGORY.name }}</h3>
        <detailed-results
          :category="category" >
        </detailed-results>
      </div>
    </div>
  </div>
</template>

<script>
import RoundHeader from '@/components/RoundHeader'
import DetailedResults from '@/components/DetailedResults'
import { RST_LEVEL, RST_EVENT, RST_CATEGORY } from "@/LocalDB";

export default {
  components: { RoundHeader, DetailedResults },
  computed:{
    levels(){
      var levelsCode = RST_EVENT.query()
        .where("competition_id", this.currentCompetition_id)
        .where("round_number", this.currentRound_number)
        .where(evt => evt.EFFECTIVE_COMPETITORS.length > 0)
        .get()
        .map(evt => evt.level)
        .filter((v, i, a) => a.indexOf(v) == i);
      var ret = RST_LEVEL.query()
        .where("competition_id", this.currentCompetition_id)
        .where("round_number", this.currentRound_number)
        .where(level => levelsCode.indexOf(level.code) >= 0)
        .get();
      return ret;
    },
  },
  methods:{
    categories(level){
      var categoriesCode = RST_EVENT.query()
        .where("competition_id", this.currentCompetition_id)
        .where("round_number", this.currentRound_number)
        .where("level", level.code)
        .where(evt => evt.EFFECTIVE_COMPETITORS.length > 0)
        .get()
        .map(evt => evt.category)
        .filter((v, i, a) => a.indexOf(v) == i);
      var ret = RST_CATEGORY.query()
        .where("competition_id", this.currentCompetition_id)
        .where("round_number", this.currentRound_number)
        .where("level", level.code)
        .where(category => categoriesCode.indexOf(category.code) >= 0)
        .get();
      return ret;
    }
  }
}
</script>
