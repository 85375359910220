<template>
    <div>
        <div>
            <b-checkbox switch size="sm" v-model="withRanks">Avec classements intermédiaires</b-checkbox>
        </div>
        <div v-if="false">
            Tri:
            <b-radio-group v-model="sortType" size="sm">
                <b-form-radio value="execution">Ordre de passage</b-form-radio>
                <b-form-radio value="rank">Classement</b-form-radio>
                <b-form-radio value="alphabetical">Alphabétique</b-form-radio>
            </b-radio-group>
        </div>
        <div ref="render" class="results-render">
        </div>
    </div>
</template>

<script>

import { RST_COMPETITOR_NOTES, RST_COMPETITOR_COMPULSORY_NOTE } from "@/LocalDB";

export default {
    value(){
        return {
            columns: [],
            table: [],
            with_ranks: false,
            typeDeTri: 'rank',
        };
    },
    props: {
            category : { type : Object, required: true, },
            event : { type: Object, default: null, },
            competitor: { type: Object, default: null},
            includeJudgeDetails: {type: Boolean, default: false},
        },
    computed:{
        withRanks:{
            get: function()  { return this.with_ranks; },
            set: function(newValue) { this.with_ranks = newValue; this.refresh();}
        },
        sortType :{
            get: function() { return this.typeDeTri; },
            set: function(newValue) { this.typeDeTri = newValue; this.refresh(); }
        }
    },
    methods:{
        refresh(){
            var rankSorted = (this.typeDeTri == 'rank');
            var figureRankSorted = (rankSorted === true && this.event != null && this.event.EVENT.hasCompulsories && !this.event.EVENT.hasRoutine && !this.event.EVENT.hasBallet );
            var figureTotalSorted = (figureRankSorted === true && this.with_ranks !== true);
            var routineRankSorted = (rankSorted === true && this.event != null && !this.event.EVENT.hasCompulsories && this.event.EVENT.hasRoutine && !this.event.EVENT.hasBallet);
            var routineTotalSorted = (routineRankSorted === true && this.with_ranks !== true);
            var precisionRankSorted = (rankSorted === true && this.event != null && this.event.EVENT.hasCompulsories && this.event.EVENT.hasRoutine && !this.event.EVENT.hasBallet);
            var precisionTotalSorted = (precisionRankSorted === true && this.with_ranks !== true);
            var balletRankSorted = (rankSorted === true && this.event != null && !this.event.EVENT.hasCompulsories && !this.event.EVENT.hasRoutine && this.event.EVENT.hasBallet);
            var balletTotalSorted = (balletRankSorted === true && this.with_ranks !== true);
            var nbRanksCols = this.category.COMPETITION.isOpen ? 2 : 1;

            var table = '<table class="results">';
            table += "<tr><th colspan=2 rowspan=2" + (this.typeDeTri === 'alphabetical' ? " class='sorted'" : "") + ">Compétiteur</th>";
            if(this.event == null || this.event.EVENT.hasCompulsories || this.event.EVENT.hasRoutine)
            {
                var colspan = 0;
                if(this.event == null || this.event.EVENT.hasCompulsories)
                {
                    colspan += (this.event == null ? this.category.RULE.numberOfCompulsories : this.event.RULE.numberOfCompulsories) + 1 + ((this.with_ranks || figureRankSorted) ? nbRanksCols : 0);
                }
                if(this.event == null || this.event.EVENT.hasRoutine)
                {
                    colspan += 3 + ((this.with_ranks || routineRankSorted) ? nbRanksCols : 0);
                }
                if(this.event == null || (this.event.EVENT.hasCompulsories && this.event.EVENT.hasRoutine))
                {
                    colspan += 1 + ((this.with_ranks || precisionRankSorted) ? nbRanksCols : 0);
                }
                table += "<th colspan='" + colspan + "'>Précision</th>";
            }
            if(this.event == null || this.event.EVENT.hasBallet ){
                colspan = (((this.with_ranks || balletRankSorted) ? 4 : 3) * nbRanksCols);
                table += "<th colspan='" + colspan + "'>Ballet</th>";
            }
            if(this.event == null || ((this.event.EVENT.hasCompulsories || this.event.EVENT.hasRoutine) && this.event.EVENT.hasBallet))
            {
                table += "<th colspan=" + nbRanksCols + 1 + ">Overall</th></tr>";
            }

            table += "<tr>";
            if(this.event == null || this.event.EVENT.hasCompulsories){
                for(var i = 0; i < this.category.RULE.numberOfCompulsories; i++)
                {
                    table += "<th>Fig." + (i+1) + "</th>";
                }
                //table += "<th " + ((figureTotalSorted === true) ? " class='sorted'" : "") + ">Tot. Fig.</th>"
                table += "<th class='total" + ((figureTotalSorted == true) ? " general" : "") + "'>Tot. Fig.</th>"
                if(this.with_ranks || figureRankSorted) table += "<th class='rank" + ((figureRankSorted === true) ? " sorted" : "") + "'>rk</th>";
            }
            if(this.event == null || this.event.EVENT.hasRoutine)
            {
                table += "<th>Exec.</th><th>Cont.</th><th class='total" + ((routineTotalSorted === true) ? " general" : "") + "'>Tot. routine</th>";
                if((this.with_ranks || routineRankSorted)) table += "<th class='rank" + ((routineRankSorted === true) ? " sorted" : "") + "'>rk</th>";
            }
            if(this.event == null || (this.event.EVENT.hasCompulsories && this.event.EVENT.hasRoutine))
            {
                table += "<th class='total" + ((precisionTotalSorted === true) ? " general" : "") + "'>Total</th>";
                if((this.with_ranks || precisionRankSorted)) table += "<th class='rank"+ ((precisionRankSorted === true) ? " sorted" : "") + "'>rk</th>";
            }
            if(this.event == null || this.event.EVENT.hasBallet)
            {
                table += "<th>Exec.</th><th>choreo</th><th class='total" + ((balletTotalSorted === true) ? " general" : "") + "'>Tot. ballet</th>"
                if(this.with_ranks || balletRankSorted) table += "<th class='rank" + ((balletRankSorted === true)? " sorted" : "") + "'>rk</th>";
            }
            if(this.event == null || ((this.event.EVENT.hasCompulsories || this.event.EVENT.hasRoutine) && this.event.EVENT.hasBallet))
            {
                table += "<th class='total'>Total</th><th class='rank" + (this.typeDeTri === 'rank' ? " sorted" : "") + "'>Rk</th></tr>";
            }

            var allNotesQry = RST_COMPETITOR_NOTES.query()
                              .where("competition_id", this.category.competition_id)
                              .where("round_number", this.category.round_number)
                              .where("level", this.category.level)
                              .where("category", this.category.category)
                              ;
            var sortedNotes = allNotesQry.get();

            if(this.event != null)
            {
              if(this.event.EVENT.hasCompulsories && this.event.EVENT.hasRoutine && this.event.EVENT.hasBallet)
                  sortedNotes.sort((a,b) => a.overall_rank - b.overall_rank);
              else if(this.event.EVENT.hasCompulsories && this.event.EVENT.hasRoutine)
                  sortedNotes.sort((a,b) => a.precision_total_rank - b.precision_total_rank);
              else if(this.event.EVENT.hasCompulsories && this.event.EVENT.hasBallet)
                  sortedNotes.sort((a,b) => a.overall_rank - b.overall_rank);
              else if(this.event.EVENT.hasCompulsories)
                  sortedNotes.sort((a,b) => a.compulsories_total_rank - b.compulsories_total_rank);
              else if(this.event.EVENT.hasRoutine)
                  sortedNotes.sort((a,b) => a.routine_total_rank - b.routine_total_rank);
              else if(this.event.EVENT.hasBallet)
                  sortedNotes.sort((a,b) => a.ballet_total_rank - b.ballet_total_rank);
            }
            else
              sortedNotes.sort((a,b) => a.overall_rank - b.overall_rank);
/*            var sortedNotes = [];

            if(this.typeDeTri == 'rank')
            {
                if(this.event === null || (this.event.EVENT.hasCompulsories && this.event.EVENT.hasRoutine && this.event.EVENT.hasBallet))
                    sortedNotes = this.SortResultsByRank(allNotes);
                else if (this.event.EVENT.hasCompulsories && this.event.EVENT.hasRoutine)
                    sortedNotes = this.SortResultsByRank(allNotes, 'precision');
                else if (this.event.EVENT.hasCompulsories && this.event.EVENT.hasBallet)
                    sortedNotes = this.SortResultsByRank(allNotes, 'mix');
                else if (this.event.EVENT.hasCompulsories && !this.event.EVENT.hasBallet)
                    sortedNotes = this.SortResultsByRank(allNotes, 'figures');
                else if (this.event.EVENT.hasRoutine && !this.event.EVENT.hasBallet)
                    sortedNotes = this.SortResultsByRank(allNotes, 'routine');
                else if (this.event.EVENT.hasBallet && !this.event.EVENT.hasCompulsories && !this.event.EVENT.hasRoutine)
                    sortedNotes = this.SortResultsByRank(allNotes, 'ballet');
            }
            else if(this.typeDeTri == 'execution')
                sortedNotes = this.SortResultsByExecutionOrder(this.category.competitors, allNotes);
            else if (this.typeDeTri === 'alphabetical')
                sortedNotes = this.SortResultsByCompetitorName(allNotes.get(), 'asc');
*/
            var rowIndex = 0;
            sortedNotes.forEach((competitorNotes) => {
                rowIndex++;
                var rowCssClass = (rowIndex % 2 == 0) ? "odd" : "even";

            //this.category.competitors.forEach(competitor => {
//                if(this.competitor === null || competitor.competitor_id === this.competitor.id)
                if(this.competitor === null || competitorNotes.competitor_id === this.competitor.id)
                {
                    var competitor = competitorNotes.COMPETITOR;
                    table += "<tr class='" + rowCssClass + "'><td colspan=2" + (this.typeDeTri === 'alphabetical' ? " class='sorted'" : "") + ">" + competitor.name + "</td>";

                   if(this.event == null || this.event.EVENT.hasCompulsories)
                   {
                     this.category.COMPULSORIES.forEach(compulsory => {
                        var competitorCompulsoryNote = RST_COMPETITOR_COMPULSORY_NOTE.query()
                              .where("competition_id", this.category.competition_id)
                              .where("round_number", this.category.round_number)
                              .where("level", this.category.level)
                              .where("category", this.category.category)
                              .where("competitor_id", competitor.id)
                              .where("compulsory_id", compulsory.compulsory_id)
                              .first();
                              table += "<td>";
                              if(competitorCompulsoryNote != null && competitorCompulsoryNote.noteValue != null){
                                table += this.arrondir(competitorCompulsoryNote.noteValue);
                              }
                              table += "</td>";
                      });
/*                         this.category.figures.forEach(figure => {
                            var competitorFigureNote = RoundFigureNote.query()
                                                    .where('category_id', this.category.id)
                                                    .where('competitor_id', competitor.competitor_id)
                                                    //.where('competitor_id', competitorNotes.competitor_id)
                                                    .where('judge_id', null)
                                                    .where('figure_id', figure.id)
                                                    .first();/
                            table += "<td>";
                            if(competitorFigureNote.note !== null){
                                table += this.arrondir(competitorFigureNote.note);
                            }
                            table += "</td>";
                        });**/

                        table += "<td class='total" + ((figureTotalSorted === true) ? " general" : "")+ "'>";
                        if(competitorNotes.compulsories_total != null)
                        {
                            table += this.arrondir(competitorNotes.compulsories_total);
                        }
                        table += "</td>";
                        if(this.with_ranks || (this.event != null && this.event.EVENT.hasCompulsories && !this.event.EVENT.hasRoutine && !this.event.EVENT.hasBallet))
                        {
                            table += "<td class='rank" + ((figureRankSorted === true) ? " sorted" : "")+ "'>";
                            if(competitorNotes.compulsories_total != null)
                                table += competitorNotes.compulsories_rank;
                            table += "</td>";
                        }
                    }
                    if(this.event == null || this.event.EVENT.hasRoutine)
                    {
                        if(competitorNotes.routine_total != null){
                            table += "<td>" + this.arrondir(competitorNotes.routine_execution) + "</td>";
                            table += "<td>" + this.arrondir(competitorNotes.routine_content) + "</td>";
                            table += "<td class='total" + ((routineTotalSorted === true) ? " general" : "") + "'>" + this.arrondir(competitorNotes.routine_total) + "</td>";
                            if(this.with_ranks || (this.event != null && !this.event.EVENT.hasCompulsories && this.event.EVENT.hasRoutine && !this.event.EVENT.hasBallet)) table += "<td class='rank" + ((routineRankSorted === true) ? "sorted" : "") + "'>" + competitorNotes.routine_total_federal_rank + "</td>";
                        }
                        else{
                            table += "<td /><td /><td />";
                            if(this.with_ranks) table += "<td/>";
                        }
                    }
                    if(this.event == null || (this.event.EVENT.hasCompulsories && this.event.EVENT.hasRoutine))
                    {
                        table += "<td class='total" + ((precisionTotalSorted === true) ? " general" : "") + "'>" + this.arrondir(competitorNotes.precision_total) + "</td>";
                        if(this.with_ranks || (this.event != null && !this.event.EVENT.hasBallet)) table += "<td class='rank" + ((precisionRankSorted === true) ? " sorted" : "") + "'>" + competitorNotes.precision_total_federal_rank + "</td>";
                    }

                    if(this.event == null || this.event.EVENT.hasBallet)
                    {
                        if(competitorNotes.ballet_total != null)
                        {
                            table += "<td>" + this.arrondir(competitorNotes.ballet_execution) + "</td>";
                            table += "<td>" + this.arrondir(competitorNotes.ballet_choreo) + "</td>";
                            table += "<td class='total" + ((balletTotalSorted === true) ? " general" : "") + "'>" + this.arrondir(competitorNotes.ballet_total) + "</td>";
                            if(this.with_ranks || balletRankSorted) table += "<td class='rank" + ((balletRankSorted === true) ? " sorted" : "" ) + "'>" + competitorNotes.ballet_total_federal_rank + "</td>";
                        }
                        else{
                            table += "<td /><td /><td />"
                            if(this.with_ranks) table += "<td/>";
                        }
                    }

                    if(this.event == null || ((this.event.EVENT.hasCompulsories || this.event.EVENT.hasRoutine) && this.event.EVENT.hasBallet))
                    {
                        if(competitorNotes.overall !== null){
                            table += "<td class='total general'>" + this.arrondir(competitorNotes.overall) + "</td>";
                        }else{
                            table += "<td class='total general/>";
                        }
                        table += "<td class='rank" + (this.typeDeTri === 'rank' ? " sorted" : "") + "'>" + competitorNotes.overall_federal_rank + '</td>';
                    }
                    table += "</tr>";
                }
            });

            table += "</table>";

            this.$refs.render.innerHTML = table;
        }
    },
    mounted(){
        this.typeDeTri = 'rank';
        this.refresh();
    }
}
</script>

<style >
    .results-render{
        display:flex;
        justify-content: center;
    }
    .results{ border-collapse: collapse !important; }
    .results > tbody > tr > th, table.results > tbody > tr > td { font-size: 0.7rem !important; orientation: landscape; border:darkgrey 1px solid !important;}
    .results > tbody > tr > th.total{ background-color: var(--resultTotalHeader);}
    .results > tbody > tr > td.total{ background-color: var(--resultTotal);}
    .results > tbody > tr > th.rank{ background-color: var(--resultRankHeader);}
    .results > tbody > tr > td.rank{ background-color: var(--resultRank);}
    .results > tbody > tr > th.total, .results > tbody > tr > td.total { font-weight: bolder; color: var(--primary);}
    .results > tbody > tr > th.sorted{ background-color: var(--sortedHeader);}
    .results > tbody > tr > td.sorted{ background-color: var(--sortedCell);}

    .even{ background-color: beige;}
    .odd{ background-color: #cdcdfd;}
</style>
