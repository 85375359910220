<template>
  <div v-if="round != null">
        <slot name="header"></slot>
        <div class="header" v-if="showTypeAndScope">{{ round.COMPETITION.COMPETITION_TYPE.name }} - échelon {{ round.COMPETITION.SCOPE.name }}</div>
        <h3 class="title">{{ round.COMPETITION.name }} - Manche {{ round.round_number }}</h3>
        <h2 class="title"><slot name="title"></slot></h2>
        <slot>
        </slot>
        <b-button v-if="showBackButton" class="back" @click="goBack"><slot name="backButtonText">&lt;&lt; Retour</slot></b-button>
        <slot name="errors">
          <fieldset class="fieldset danger" v-if="showErrors || errors.length > 0">
            <legend>A corriger...</legend>
            <ul>
              <div v-for="element in errors" :key="element">
                <li>{{ element }}</li>
              </div>
            </ul>
          </fieldset>
        </slot>
        <slot name="warnings">
          <fieldset class="fieldset warnings" v-if="showWarnings || (warnings != null && warnings.length > 0)">
            <legend>Avertissements</legend>
            <ul>
              <div v-for="element in warnings" :key="element">
                <li>{{ element }}</li>
              </div>
            </ul>
          </fieldset>
        </slot>
        <slot name="footer"/>
      <!--
        <div class="cdf" v-if="round.isPartOfCdf && round.isSelective">Manche qualificative pour le CdF {{ round.cdfEdition }}</div>
        <div class="cdf" v-if="round.isPartOfCdf && !round.isSelective">Manche de finale du CdF {{ round.cdfEdition }}</div>
        <div class="location selective" v-if="round.isPartOfCdf && round.isSelective">{{ round.location }}</div>
        <div class="roundNo" v-if="round.no">Manche {{ round.no }}</div>
        <div class="location final" v-if="round.isPartOfCdf && !round.isSelective">{{ round.location}}</div>
        -->
  </div>
</template>

<script>
import RoundsConfigurationHelper from '@/services/helpers/roundsConfigurationHelper';

export default {

  props:{
    value: {type : Object, required: false},
    showErrors: { type:Boolean, default: false },
    showWarnings: { type:Boolean, default: false },
    showTypeAndScope: { type: Boolean, default: true},
    showBackButton: { type: Boolean, default: false},
  },

  computed:{
        round(){
          return (this.value == null) ? this.currentRound : this.value;
        },
  },
  methods:{
        errors(){
          //console.log('RoundHeader', this.round);
            return (this.round != null)
                ? RoundsConfigurationHelper.missingRequirementsBeforeRun(this.round.competition_id)
                : '';
        },
        warnings(){
          return (this.round != null)
                ? RoundsConfigurationHelper.warningsBeforeRun(this.round.competition_id)
                : '';
        }
    },
}
</script>

<style scoped>
  div.header { font-size: 0.7rem; }
</style>
